import type { DetailedPlace } from '~/modules/location/type';
import {
    getFormattedPlaceName,
    getPlaceTypeName,
} from '~/modules/location/util';

export interface PlaceFinderDropdownItem<T> {
    header: string;
    value: T;
    prefix: string;
}

/**
 * Generate dropdown options for the places.
 * @param places The places to generate the dropdown options for.
 * @returns The dropdown options for the place finder.
 */
export const getPlaceDropdownOptions = (
    places: DetailedPlace[]
): PlaceFinderDropdownItem<DetailedPlace>[] => {
    const uniquePlaces: PlaceFinderDropdownItem<DetailedPlace>[] = [];
    for (const place of places) {
        const header = getFormattedPlaceName(place, true);
        if (header && !uniquePlaces.some((item) => item.header === header)) {
            uniquePlaces.push({
                header,
                value: place,
                prefix: getPlaceTypeName(place, true),
            });
        }
    }
    return uniquePlaces;
};
