import type { DetailedPlace } from '~/modules/location/type';

/**
 * Get the formatted place name for a place object.
 * @param place The place to format.
 * @param includePostCode Include the post code.
 * @returns The formatted place name.
 */
export const getFormattedPlaceName = (
    place: DetailedPlace,
    includePostCode: boolean = false
) => {
    const {
        fullAddress,
        postCode,
        suburb,
        district,
        city,
        region,
        state,
        country,
    } = place;

    const segments = fullAddress
        ? fullAddress.split(/\s*,\s*/)
        : [suburb, district, city, region, state, country];
    if (includePostCode && postCode) {
        segments.unshift(postCode);
    }
    // Filter out empty segments and deduplicate.
    return [...new Set(segments.filter(Boolean))].join(', ');
};
